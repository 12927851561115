import React from "react";
import Reveal from "react-reveal/Reveal";

const SkillsTwo = () => {
  return (
    <>
      <div className="shane_tm_section">
        <div className="shane_tm_skills">
          <div className="container">
            <div className="skills_inner">
              <Reveal effect="fadeInLeft">
                <div className="left">
                  <div className="shane_tm_title">
                    <h3>I have high skills in developing and programming</h3>
                  </div>
                  <div className="text">
                    <p>
                      Experienced Software Engineer with a demonstrated history of working in the government administration industry.
                      Skilled in JAVA EE, PHP, React Js, web services (REST, SOAP) Computer Science, Databases,
                      and Content Management Systems (CMS). Strong business development professional with a MSc in Computational
                      and Internet Technologies and Applications focused in Informatics and Telematics from Harokopio University of Athens.
                    </p>
                  </div>
                </div>
                {/* End .left */}
              </Reveal>

              <Reveal effect="fadeInLeft">
                <div className="right">
                  <div className="tokyo_progress">

                    <div className="progress_inner">
                      <span>
                        <span className="label">Web Development</span>
                        <span className="number">95%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                            className="bar_in"
                            style={{ width: 95 + "%" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* End .progress_inner */}

                    <div className="progress_inner">
                      <span>
                        <span className="label">Java (and Framworkds)</span>
                        <span className="number">92%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                              className="bar_in"
                              style={{ width: 92 + "%" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* End .progress_inner */}

                    <div className="progress_inner">
                      <span>
                        <span className="label">PHP (and Frameworks)</span>
                        <span className="number">86%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                              className="bar_in"
                              style={{ width: 86 + "%" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* End .progress_inner */}

                    <div className="progress_inner">
                      <span>
                        <span className="label">React</span>
                        <span className="number">84%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                              className="bar_in"
                              style={{ width: 84 + "%" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* End .progress_inner */}

                    <div className="progress_inner">
                      <span>
                        <span className="label">Web Services - API</span>
                        <span className="number">82%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                              className="bar_in"
                              style={{ width: 82 + "%" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* End .progress_inner */}


                  </div>
                </div>
                {/* End .right */}
              </Reveal>
            </div>
          </div>
          {/* End .conainer */}
        </div>
      </div>
    </>
  );
};

export default SkillsTwo;
