import React from "react";
import Social from "../SocialTwo";
import slider from "../../images_media/slider/1.jpg";

const Slider = () => {
  return (
    <div className="slider-two">
      <div className="shane_tm_hero" id="home" data-style="three">
        <div className="background">
          <div
            className="image"
            style={{

              backgroundImage: `url(${slider})`,
            }}
          />
        </div>
        {/* End .background */}

        <div className="container">
          <div className="content">
            <div className="job_wrap">
              <h4 className="job">
                <span>Constantinos</span> Athanasiou
              </h4>
            </div>
            {/* End title */}

            <div className="job_wrap">
              <span className="job">
                Software Engineer
              </span>
            </div>
            {/* End designation */}
            <Social />
          </div>
          {/* End content */}

          <div className="shane_tm_down loaded">
            <div className="line_wrapper">
              <div className="line"/>
            </div>
          </div>
        </div>
        {/* End .container */}
      </div>
    </div>
  );
};

export default Slider;
