import React from "react";
import Reveal from "react-reveal/Reveal";
import logoImage from "../../images_media/logo/new/1.jpg";
import developer from "../../images_media/about/developer.jpg";

const AboutTwo = () => {
  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div className="image">
                  <img src={logoImage}  alt="placeholder" />
                  <Reveal effect="fadeInLeft">
                    <div
                      className="main"
                      style={{

                        backgroundImage: `url(${developer})`,
                      }}
                    />
                  </Reveal>
                </div>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <Reveal effect="fadeInLeft">
                  <div className="shane_tm_title">
                    <span>About Me</span>
                    <h3>Creative Developer based in Athens, Greece</h3>
                  </div>
                  <div className="text">
                    <p>
                      Everything started with the passion of creation with the use of new technology.
                      The incredible power given to you by a computer leads you to offer dynamic solutions that help people's everyday life.
                      I offer innovative applications that address clients most challenging business, technology, and system integration needs.
                    </p>
                  </div>
                  <div className="shane_tm_button" >

                    <a href="https://www.linkedin.com/in/constantinosathanasiou/" style={{margin:'10px'}}
                       target="_blank" rel="noreferrer">
                      Linkedin
                      <img
                          className="svg shane_tm_button"
                          src={`/img/svg/social/linkedin.svg`}
                          alt="social"
                      />

                    </a>

                    <a href="https://github.com/athanasiouconst/"style={{margin:'10px'}}
                       target="_blank"
                       rel="noreferrer">
                      Github
                      <img
                          className="svg shane_tm_button"
                          src={`/img/svg/social/github.svg`}
                          alt="social"
                      />
                    </a>
                  </div>
                </Reveal>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default AboutTwo;
