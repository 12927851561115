import React from "react";
import { Tabs,  TabPanel} from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import {SRLWrapper} from "simple-react-lightbox";
import Reveal from "react-reveal/Reveal";
import afoimichail from "../../images_media/portfolio/afoimichail.jpg";
import lovemyself from "../../images_media/portfolio/lovemyself.jpg";

const PortfolioTwo = () => {
    return (
        <div className="shane_tm_section" id="portfolio">
            <SimpleReactLightbox>
                <div className="shane_tm_portfolio">
                    <div className="container">
                        <div className="positon-relative">
                            <div className="shane_tm_title">
                                <div className="title_flex">
                                    <div className="left">
                                        <span>Portfolio</span>
                                        <h3>Creative Portfolio</h3>
                                    </div>
                                </div>
                            </div>
                            {/* End shane_tm_title */}
                            <div className="portfolio_filter">
                                <Tabs>
                                    {/*<TabList>*/}
                                    {/*  <Tab>All</Tab>*/}
                                    {/*  <Tab>Design</Tab>*/}
                                    {/*  <Tab>Branding</Tab>*/}
                                    {/*  <Tab>Photography</Tab>*/}
                                    {/*</TabList>*/}
                                    {/* End tablist */}
                                    <div className="portfolio_list">
                                        <SRLWrapper>

                                            <TabPanel>
                                                <ul className="gallery_zoom">

                                                    <li>
                                                        <Reveal effect="fadeIn">
                                                            <div className="inner">
                                                                <div
                                                                    className="entry shane_tm_portfolio_animation_wrap">
                                                                    <a href={afoimichail}>

                                                                        <img
                                                                            src={afoimichail}
                                                                            alt="afoimichail"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="mobile_title">
                                                                    <h3><a
                                                                        href="https://afoimichail.gr/"
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        afoimichail.gr
                                                                    </a></h3>
                                                                    <span>React</span>
                                                                </div>
                                                            </div>
                                                            <a
                                                                href="https://afoimichail.gr/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                afoimichail.gr
                                                            </a>
                                                        </Reveal>
                                                    </li>
                                                    {/* End single image block */}

                                                    <li>
                                                        <Reveal effect="fadeIn">
                                                            <div className="inner">
                                                                <div
                                                                    className="entry shane_tm_portfolio_animation_wrap">
                                                                    <a href={lovemyself}>
                                                                        <img
                                                                            src={lovemyself}
                                                                            alt="lovemyself"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="mobile_title">
                                                                    <h3>lovemyself</h3>
                                                                    <span>CMS-Drupal</span>
                                                                </div>
                                                            </div>
                                                            <a
                                                                href="https://lovemyself.gr/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                lovemyself.gr
                                                            </a>
                                                        </Reveal>
                                                    </li>
                                                    {/* End single image block */}

                                                </ul>
                                                {/* End portfolio list */}

                                            </TabPanel>
                                            {/* END ALL PORTFOLIO GALLERY */}


                                        </SRLWrapper>
                                        {/* End tabpanel */}
                                    </div>
                                    {/* End list wrapper */}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </SimpleReactLightbox>
        </div>
    );
};

export default PortfolioTwo;
